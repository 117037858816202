<template>
  <div class="container">
    <Page v-model="keys" :info="info" >
      <template slot="left">
        <div class="left-box">
          <div class="showVideo" v-if="active.id === 7">
            <video-player
              class="video-player vjs-custom-skin"
              ref="videoPlayer"
              :playsinline="true"
              :options="video.playerOptions"
            >
            </video-player>
            <p>点击播放 {{video.title}}</p>
          </div>
          <Swiper class="swiper" :list="bannerImg" v-if="active.id === 8" :auto="true">
            <template v-slot="slotProps">
              <div
                v-for="(item, index) in bannerImg"
                :key="index"
                class="item"
                :style="
                  'width:' +
                  slotProps.width +
                  'px;' +
                  'background: url(' +
                  (imgUrl + item) +
                  ') center center / cover no-repeat;'
                "
              ></div>
            </template>
          </Swiper>
          <div class="viewContent clearfix scroller">
            <div
              class="clearfix ql-editor"
              v-for="(item, index) in leftList"
              :key="index"
            >
              <h3 v-html="item.name"></h3>
              <div v-html="item.description"></div>
            </div>
          </div>
        </div>
      </template>
      <template slot="right">
        <div class="viewContent">
          <div
            class="clearfix ql-editor"
            v-for="(item, index) in rightList"
            :key="index"
          >
            <h3 v-html="item.name"></h3>
            <div v-html="item.description"></div>
          </div>
          <div class="clearfix numBox">
            <ul class="fl one">
              <li v-for="(item, index) in numList" :key="index">
                <p>{{ item.name }}</p>
                <p>
                  <span
                    ><countTo :startVal="0" :endVal="item.extend2 * 1" /></span
                  >{{ item.extend3 }}
                </p>
              </li>
            </ul>
          </div>
        </div>
      </template>
    </Page>
    <Foot></Foot>
  </div>
</template>

<script>
import Page from "@/components/page.vue";
import Foot from "@/components/foot.vue";
import Swiper from "@/components/swiper.vue";
import countTo from "vue-count-to";
export default {
  name: "parkP",
  components: {
    Foot,
    Page,
    countTo,
    Swiper,
  },
  data() {
    return {
      keys: [],
      playerOptions: {
        playbackRates: [0.7, 1.0, 1.5, 2.0], //播放速度
        autoplay: false, //如果true,浏览器准备好时开始回放。
        muted: false, // 默认情况下将会消除任何音频。
        loop: false, // 导致视频一结束就重新开始。
        preload: "auto", // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: "zh-CN",
        aspectRatio: "18:9", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [
          {
            type: "video/mp4",
            src: "", //视频url地址
          },
        ],
        // poster: require("../assets/Index/video01.png"), //你的封面地址
        // width: document.documentElement.clientWidth,
        notSupportedMessage: "此视频暂无法播放，请稍后再试", //允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          timeDivider: true,
          durationDisplay: true,
          remainingTimeDisplay: false,
          fullscreenToggle: true, //全屏按钮
        },
      },
    };
  },
  computed: {
    info() {
      return this.$store.state.park || {};
    },
    video() {
      let item = this.banners[0] || {};
      let url = this.vidoUrl + item.images[0] || "";
      return {
        title: item.name,
        playerOptions: {
          playbackRates: [0.7, 1.0, 1.5, 2.0], //播放速度
          autoplay: false, //如果true,浏览器准备好时开始回放。
          muted: false, // 默认情况下将会消除任何音频。
          loop: false, // 导致视频一结束就重新开始。
          preload: "auto", // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
          language: "zh-CN",
          aspectRatio: "18:9", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
          fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
          sources: [
            {
              type: "video/mp4",
              src: url, //视频url地址
            },
          ],
          // poster: require("../assets/Index/video01.png"), //你的封面地址
          // width: document.documentElement.clientWidth,
          notSupportedMessage: "此视频暂无法播放，请稍后再试", //允许覆盖Video.js无法播放媒体源时显示的默认信息。
          controlBar: {
            timeDivider: true,
            durationDisplay: true,
            remainingTimeDisplay: false,
            fullscreenToggle: true, //全屏按钮
          },
        },
      };
    },
    active() {
      let obj = {};
      let list = this.info.children || [];
      this.keys.forEach((id) => {
        list.forEach((item) => {
          if (item.id === id) {
            obj = item;
            list = item.children || [];
          }
        });
      });
      return obj;
    },
    banners() {
      return JSON.parse(JSON.stringify(this.active.banners || [])).map(
        (item) => {
          item.images = (item.images || "").split(",").filter((item) => item);
          return item;
        }
      );
    },
    bannerImg() {
      return this.banners[0]?.images || [];
    },
    leftList() {
      return (this.active.attributes || []).filter(
        (item) => item.extend1 === "left"
      );
    },
    rightList() {
      return (this.active.attributes || []).filter(
        (item) => item.extend1 === "right"
      );
    },
    numList() {
      return (this.active.attributes || []).filter(
        (item) => item.extend1 === "num"
      );
    },
  },
  created() {},
  methods: {
    showOrhien() {
      this.show = !this.show;
    },
  },
};
</script>
<style lang="scss" scoped>
.showVideo {
  width: 100%;
  height: 238px;
  text-align: left;
  p {
    font-size: 12px;
    color: #b5afaf;
    margin-top: 10px;
  }
}
.left-box {
  height: 100%;
  .swiper {
    height: 220px;
    .item {
      float: left;
      height: 100%;
    }
  }
  .viewContent {
    height: 60%;
    margin-top: 10px;
    overflow: auto;
    padding-right: 5px;
    text-align: left;
    div {
      padding-top: 10px;
      width: 100%;
      float: left;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
    }
    h3 {
      font-size: 18px;
      color: #fff;
      font-weight: bold;
      position: relative;
      height: 48px;
    }
    h3::after {
      content: "";
      display: inline-block;
      width: 29px;
      height: 2px;
      background-color: rgba(255, 255, 255, 0.5);
      position: absolute;
      left: 0;
      top: 30px;
    }
    div.scroller {
      height: 73%;
      overflow: auto;
    }
  }
  @media screen and (max-width: 1024px) {
    .viewContent {
      height: 60%;
    }
  }
  @media screen and (max-height: 740px) {
    .viewContent {
      height: 54%;
    }
  }
}
.numBox {
  ul {
    li {
      width: 50%;
      float: left;
      padding-right: 50px;
      box-sizing: border-box;
    }
  }
}
</style>
<style>
.vjs-big-play-button {
  left: 50% !important;
  top: 50% !important;
  transform: translate(0, -50%);
}
</style>
