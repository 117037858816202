var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_c('Page',{attrs:{"info":_vm.info},on:{"chage":_vm.chageFn,"bclick":_vm.serviceCloseFn},model:{value:(_vm.keys),callback:function ($$v) {_vm.keys=$$v},expression:"keys"}},[_c('template',{slot:"left"},[_c('div',{staticClass:"title",class:_vm.banners.length > 3 ? 'more' : ''},_vm._l((_vm.banners),function(item,index){return _c('a',{key:index,class:_vm.bannerIndex === index ? 'active' : '',on:{"click":function($event){_vm.bannerIndex = index}}},[_vm._v(_vm._s(item.name))])}),0),_c('Swiper',{staticClass:"swiper",attrs:{"list":_vm.bannerImg,"auto":true},scopedSlots:_vm._u([{key:"default",fn:function(slotProps){return _vm._l((_vm.bannerImg),function(item,index){return _c('div',{key:index,staticClass:"item",style:('width:' +
              slotProps.width +
              'px;' +
              'background: url(' +
              (_vm.imgUrl + item) +
              ') center center / cover no-repeat;')})})}}])}),_c('div',{staticClass:"servce-box clearfix"},[_vm._l((_vm.tags),function(item,index){return [(index < 5)?_c('div',{key:index,staticClass:"li"},[_c('p',{staticClass:"l-a"},[_c('img',{attrs:{"src":_vm.imgUrl + item.logo}})]),_c('p',{staticClass:"l-b"},[_vm._v(_vm._s(item.name))])]):_vm._e()]})],2),_c('div',{staticClass:"introductionBox"},[_c('h3',{domProps:{"innerHTML":_vm._s(_vm.active.cnName)}}),_c('div',{staticClass:"scroller",domProps:{"innerHTML":_vm._s(_vm.active.description)}})])],1),_c('template',{slot:"right"},[_c('div',{staticClass:"title"},[_c('a',{staticClass:"active"},[_vm._v(_vm._s(_vm.active.nameAlias))])]),_c('Swiper',{staticClass:"swiper-right",attrs:{"list":_vm.rightList},scopedSlots:_vm._u([{key:"default",fn:function(slotProps){return _vm._l((_vm.rightList),function(item,index){return _c('div',{key:index,staticClass:"swiper-item",style:('width:' + slotProps.width + 'px;')},[_c('div',{staticClass:"service-list clearfix"},_vm._l((item),function(d,i){return _c('div',{key:i,staticClass:"item",on:{"click":function($event){return _vm.itemFn(d)}}},[_c('div',{staticClass:"i-img",style:('background: url(' +
                    (_vm.imgUrl + d.logo) +
                    ')center center / cover no-repeat;')}),_c('p',[_vm._v(_vm._s(d.name))])])}),0)])})}}])})],1)],2),_c('Service',{ref:"service"}),_c('Foot')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }