<template>
  <canvas
    ref="myCanvas"
    :width="width"
    :height="height"
    style="position: absolute; left: 0; top: 0; z-index: 5"
  ></canvas>
</template>

<script>
export default {
  name: "footC",
  components: {},
  data() {
    return {
      show: true,
      width: 480,
      height: 800,
    };
  },
  created() {
    this.width = document.body.offsetWidth;
    this.height = document.body.offsetHeight;
  },
  computed: {},
  beforeDestroy() {
    clearInterval(this.run);
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      var W = this.width,
        H = this.height,
        ctx,
        angle = 0,
        len = 15,
        count = 100;
      var canvas = this.$refs.myCanvas;
      ctx = canvas.getContext("2d");
      console.log(W, H);
      ctx.strokeStyle = "rgba(255, 255, 255, 0.7)";
      this.run = setInterval(draw, 200);
      function draw() {
        ctx.clearRect(0, 0, W, H);
        xiayus();
      } //画线

      function xiayu(x, y,) {
        ctx.beginPath();
        ctx.moveTo(x, y);
        //console.log(y, len);
        ctx.lineTo(x + angle, y + len);
        ctx.lineWidth = 1.2;
        ctx.stroke();
      }
      function xiayus() {
        for (var i = 1; i <= count; i++) {
          xiayu(Math.random() * W, Math.random() * H, angle);
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
</style>
