<template>
  <div
    class="aboutUs"
    :style="info.background ? 'background-image: url(' + imgUrl + info.background + ');' : ''"
  >
    <div class="aboutUsBox">
      <div class="contactUs">
        <div class="contactUsHeader">
          <h3>联系我们</h3>
          <p>CONTACT US</p>
        </div>
        <ul class="contactUsLi clearfix">
          <li v-for="(item, index) in telList" :key="index">
            <em></em>
            <div class="fl">
              <p class="f-s-14">{{ item.name }}：</p>
              <p class="f-s-30 f-b">{{ item.extend2 }}</p>
              <p class="f-s-14">地址：{{ item.extend3 }}</p>
            </div>
          </li>
        </ul>
      </div>
      <div class="attentionUs" v-if="followList && followList.length">
        <div class="contactUsHeader">
          <h3>关注与订阅</h3>
          <p>PAY ATTENTION TO US</p>
        </div>
        <ul class="clearfix">
          <li v-for="(item, index) in followList" :key="index">
            <div>
              <img :src="imgUrl + item.logo" />
              <p>{{ item.name }}</p>
            </div>
            <ul>
              <li v-for="(d, index) in item.description" :key="index">
                {{ d }}
              </li>
            </ul>
          </li>
        </ul>
      </div>
      <p class="f-s-14 jygw">
        {{ website.name }}：<a
          v-bind:hreflang="website.extend2"
          target="_bank"
          >{{ website.extend2 }}</a
        >
      </p>
    </div>
    <Foot />
  </div>
</template>

<script>
// @ is an alias to /src
import Foot from "@/components/foot.vue";

export default {
  name: "AboutUs",
  components: {
    Foot,
  },
  computed: {
    info() {
      return this.$store.state.contactus || {};
    },
    telList() {
      return (this.info.attributes || []).filter(
        (item) => item.extend1 === "tel"
      );
    },
    followList() {
      return JSON.parse(JSON.stringify(this.info.attributes || []))
        .filter((item) => item.extend1 === "follow")
        .map((item) => {
          item.description = item.description.split("\n");
          return item;
        });
    },
    comm() {
      return this.$store.state.comm || {};
    },
    website() {
      return (
        (this.comm.attributes || []).filter(
          (item) => item.extend1 === "website"
        )[0] || {}
      );
    },
  },
};
</script>
<style lang="scss" scoped>
.aboutUs {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  background:  center center no-repeat;
  background-size: cover !important;
}
.aboutUs > div.aboutUsBox {
  text-align: center;
  position: absolute;
  width: 100%;
  top: 40%;
  margin-top: -248px;
}
.aboutUs .contactUsHeader {
  text-align: center;
}
.aboutUs .contactUsHeader h3 {
  font-size: 30px;
  color: #fff;
  width: 227px;
  position: relative;
  display: inline-block;
}
.aboutUs .contactUsHeader h3::after,
.aboutUs .contactUsHeader h3::before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 42px;
  height: 1px;
  background-color: rgba(255, 255, 255, 0.24);
  top: 20px;
}
.aboutUs .contactUsHeader h3::before {
  left: 0;
}
.aboutUs .contactUsHeader h3::after {
  right: 0;
}
.aboutUs .contactUsHeader p {
  font-size: 18px;
  font-family: "enFont";
}
.aboutUs .contactUsLi {
  display: inline-block;
  margin-top: 60px;
}
.aboutUs .contactUsLi li {
  float: left;
  width: 380px;
  height: 78px;
  border-left: 2px solid rgba(255, 255, 255, 0.5);
  padding: 0 10px 0 25px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.aboutUs .contactUsLi li:first-child {
  border-left: none;
}
.aboutUs .contactUsLi li em {
  display: inline-block;
  width: 48px;
  height: 78px;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAzCAYAAADRlospAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAABmJLR0QAAAAAAAD5Q7t/AAAACXBIWXMAAAsSAAALEgHS3X78AAAFBklEQVRo3s2aXYhVVRTHf+s64wyNpWk5WRikJn1hRANTYeIHGD4VRaUWNG+WSdZABIOYJVgPJQblQ0+W1eCLRB+alAaa4UOUk4GUGfRhM6lTpjmOM+P8e9jnyvW49/mYe+9c/3C49+691jr//1l7n7P3OteoACRdCcwCJgNTgHHAWOAE0AucjL7/YGa9lThnEVYG6bnAImAeMB2YmMHtBHAI+BL43My+qKSYLKQbJLVL6lJlcCCKN3Y0yD8m6XCFiMfxk6SHq0W8XlJnlYjHsVlSfSXJT5G0f5TIF9ElKct8AhImsaTLgW+BGVVJbTIOAi1m1pdmWEjo+6xG5AFuBj7JYugVIOll4J4akS9inqTn04wuGkKSpgM/15h8Ka4zsz9Dnb4MrKs14xheTOq8IAOSpgBHKOMJXQWcA5pDS5B4BhZfYuQBxgAPhjrjAhbVmm0AD6QKkNQE3J0z8BDw7CgIaJXUkCgAuBG3DM6DlWb2BrCsygImAbekCZiZM+g6M9sIYGZvAw8Bv1RRxA2+xrqS71dlDPQv0FEkX4SZbZX0EbAUmA/chdsjjAMacTeHIWAg+jwbHfW4idpI8gi4Jk3A+ATnYWAX8CnQaWZ/+YzMbAh4NzqK66nxQFMkYCAiPQj0A31AQ8TjMtwSohX/s6gp8bJKWuNZGQ5JejN6Oo8a5N93rEjLwGlP/zYzW8HoYz8wLdb2iKReYK+Z/VZsLJ3E/3kCHaoBeYCjnrZ7gQ+AHyW94BPgm0C+rJyHpDpJuW69ksZJqksx+yehrxF4VVJ7XIBv89CfQKQDOAwclrRdUksK8RZJ20t8OhLM+0nH69Ha7fwJVnomzmsBMpsD28FZAftZoT1wwH6NsmFZ0eF+Sac9Bjs9wRckBNwTILQnwWdBGQLWFiRNAz7E3YfjmC9paqztvoS0tkq64HkS/W5N8EmKlwYVgJcCnSeATtxDrBTdCQFPcfH47Y/aQ0iKl4bhAhBP4TCwGphhZkvN7Eis/33gTCDgejM7W9oQ/V4fsD8TxRs5JJ2KjasBSYUUnzmSemJ+m1J8NsXseyTNCdhmngN1QA8Xlk/qgS5JW3FP6t1mtiN2VXdLug14FLcI/MrMdpIAM2uL7jqzgePAFjM7XtbVh6Esass9SW7kyEBbAXiF5DLKJLlS+qWI7YVoks0G9iYYPlNrph48d9GyXtJySd8E0nX1aDGTtDFh2JxWWsVOrl4fx4acJBZGY/kpSVl3e0XfuZI6JP3q4dGeJcCTHsdhSZMzEngv5tsraf4IMuET0JLFsVH+tVFnBt9VgdQPSJopaYKkiSnHBElLPDFOypV/MqnvCBBZmOAzNrraIQxIOibp75TjWJTxOD7Ok746Scc9QY4q8FJOUpOkQVUPbXnH4JJAoJ0JPntyEMqDPmUdPjFCOwIB3wnY314lAY/7zpdaiZZ74daNe/MexxYzW+zxuRNX27kjakrbA4dQj1urvWVmG0YkICK0CNgW6N4HLDez7zx+V+CqcIar8w+a2bmSC5OGOqC36FMWJD2dkuJ1km7NEKdZ7r8VFUGulxmSVgFrU8y+Bg7gUt8ITAWagWuBCbg/g+wzs7yl/MpA0hMVmJB/SBpTEwGRiHmSDpYh4Kyk5poJiETUyy3W+kYoYk75LCoj5HpJq5XtPxWDko5I+l5Sa/lnr/AbSbnK3E24iTse9x6gG1es7cVN7N/NbKBS5/wfy8X4TOTetDUAAAAASUVORK5CYII=)
    center no-repeat;
  background-size: contain;
  float: left;
  margin-right: 14px;
}
.aboutUs .contactUsLi li > div {
  width: calc(100% - 62px);
}
.aboutUs .contactUsLi li p {
  text-align: left;
  line-height: 30px;
}
.aboutUs .f-s-14 {
  font-size: 14px;
}
.aboutUs .f-s-30 {
  font-size: 30px;
}
.aboutUs .f-b {
  font-weight: bold;
  font-family: "enFont";
}
.aboutUs .attentionUs {
  margin-top: 70px;
}
.aboutUs .attentionUs > ul {
  display: inline-block;
  margin-top: 35px;
}
.aboutUs .attentionUs > ul > li {
  float: left;
  margin: 0 22px;
}
.aboutUs .attentionUs > ul > li div {
  float: left;
  margin-right: 10px;
}
.aboutUs .attentionUs > ul > li div img {
  width: 98px;
  height: 98px;
}
.aboutUs .attentionUs > ul > li div p {
  font-size: 14px;
  margin-top: 8px;
  width: 98px;
}
.aboutUs .attentionUs > ul > li > ul {
  float: left;
}
.aboutUs .attentionUs > ul > li > ul li {
  text-align: left;
  position: relative;
  padding-left: 10px;
  font-size: 12px;
  line-height: 18px;
}
.aboutUs .attentionUs > ul > li > ul li::before {
  content: "\B7";
  font-size: 20px;
  position: absolute;
  left: 0;
}
.aboutUs .jygw {
  margin-top: 15px;
}
</style>
