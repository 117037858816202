<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<script>
import service from '@/utils/request'
export default {
  data() {
    return {
      show: true,
    };
  },
  created() {
    service.get('/app/portal/list?type=').then(res =>{
      console.log(res)
      this.$store.commit('setData',res.data.projectList)
    })
  },
  
};
</script>
<style>

</style>
