<template>
  <div class="container">
    <footer class="container">
      {{copyright.extend2}} 版权所有&emsp;
      {{icp.extend2}} &emsp;|&emsp;网站策划与设计：
      <em><span class="gzs">{{design.extend2}}</span></em
      >&emsp;|&emsp;网站技术支持：{{support.extend2}} 联系人：{{contacts.extend2}}
    </footer>
    <StudioIntroBox ref="studioIntroBox"></StudioIntroBox>
    <Menu @studioIntroBoxShow="studioIntroBoxShow"></Menu>
  </div>
</template>

<script>
import Menu from "@/components/menu.vue";
import StudioIntroBox from "@/components/studioIntroBox.vue";
export default {
  name: "footC",
  components: {
    Menu,
    StudioIntroBox
  },
  data() {
    return {
      show: true,
    };
  },
  created() {},
  computed: {
    info() {
      return this.$store.state.comm || {};
    },
    copyright() {
      return (this.info.attributes || []).filter(
        (item) => item.extend1 === "copyright"
      )[0] || {}
    },
    icp() {
      return (this.info.attributes || []).filter(
        (item) => item.extend1 === "icp"
      )[0] || {}
    },
    design() {
      return (this.info.attributes || []).filter(
        (item) => item.extend1 === "design"
      )[0] || {}
    },
    support() {
      return (this.info.attributes || []).filter(
        (item) => item.extend1 === "support"
      )[0] || {}
    },
    contacts() {
      return (this.info.attributes || []).filter(
        (item) => item.extend1 === "contacts"
      )[0] || {}
    },
  },
  methods: {
    showOrhien() {
      this.show = !this.show;
    },
    studioIntroBoxShow(){
      this.$refs.studioIntroBox.showOrhien()
    },
  },
};
</script>
<style lang="scss" scoped>
</style>
