
<template>
  <div ref="mySwiper" class="swiper-box">
    <div
      ref="swiperc"
      class="swiper-c"
      :style="
        'transform: translate3d(-' +
        left +
        'px, 0px, 0px); transition-duration: 300ms;;width: ' +
        widthbox +
        'px;'
      "
    >
      <slot v-bind:width="width"></slot>
    </div>
    <div class="swiper-pagination" :style="'width: ' + width + 'px'">
      <span
        class="swiper-pagination-bullet"
        :class="index === item - 1 ? 'swiper-pagination-bullet-active ' : ''"
        v-for="item in length"
        :key="item"
      ></span>
    </div>
    <div
      class="swiper-button-prev"
      :class="index === 0 ? 'dis' : ''"
      @click="prev"
    ></div>
    <div
      class="swiper-button-next"
      :class="index === length - 1 || length === 0 ? 'dis' : ''"
      @click="next"
    ></div>
  </div>
</template>

<script>
// swiper options example:
export default {
  name: "carrouselC",
  data() {
    return {
      left: 0,
      width: 100,
      length: 1,
      index: 0,
    };
  },
  props: {
    list: {
      type: Array,
      default() {
        return [];
      },
    },
    auto: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  computed: {
    widthbox() {
      return this.width * this.length;
    },
  },
  watch: {
    list() {
      this.$nextTick(() => {
        this.length = this.$refs.swiperc.childNodes.length;
        this.index = 0;
      });
    },
  },
  mounted() {
    this.width = this.$refs.mySwiper.offsetWidth;
    this.length = this.$refs.swiperc.childNodes.length;
    if (this.auto) {
      this.autoFn();
    }
  },

  beforeDestroy() {
    clearInterval(this.t);
  },
  methods: {
    autoFn() {
      this.t = setInterval(() => {
        this.index = this.index + 1;
        if (this.index >= this.length) {
          this.index = 0;
        }
        this.left = this.width * this.index;
      }, 3000);
    },
    prev() {
      this.index = this.index - 1;
      if (this.index <= 0) {
        this.index = 0;
      }
      this.left = this.width * this.index;
    },
    next() {
      if (this.length === 0) return;
      this.index = this.index + 1;
      if (this.index >= this.length - 1) {
        this.index = this.length - 1;
      }
      this.left = this.width * this.index;
    },
  },
};
</script>
<style lang="scss" scoped>
.swiper-box {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  .swiper-c {
    height: calc(100% - 20px);
    overflow: hidden;
  }
  .swiper-button-prev {
    cursor: pointer;
    background-image: none;
    background-color: rgba(0, 0, 0, 0.6);
    border-radius: 50%;
    width: 40px;
    height: 40px;
    text-align: center;
    line-height: 40px;
    position: absolute;
    left: 20px;
    top: 50%;
    transform: translateY(-50%);
    &::before {
      content: " ";
      display: block;
      width: 15px;
      height: 15px;
      border-top: 2px solid #fff;
      border-right: 2px solid #fff;
      transform: rotate(-135deg);
      position: relative;
      left: 15px;
      top: 12px;
    }
    &.dis {
      &::before {
        border-color: #777;
      }
    }
  }
  .swiper-button-next {
    cursor: pointer;
    background-image: none;
    background-color: rgba(0, 0, 0, 0.6);
    border-radius: 50%;
    width: 40px;
    height: 40px;
    text-align: center;
    line-height: 40px;
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    &::before {
      content: " ";
      display: block;
      width: 15px;
      height: 15px;
      border-top: 2px solid #fff;
      border-right: 2px solid #fff;
      transform: rotate(45deg);
      position: relative;
      left: 7px;
      top: 12px;
    }
    &.dis {
      &::before {
        border-color: #777;
      }
    }
  }

  .swiper-pagination {
    text-align: center;
    height: 20px;
    position: absolute;
    left: 0;
    bottom: 0px;
    .swiper-pagination-bullet {
      width: 8px;
      height: 8px;
      display: inline-block;
      border-radius: 100%;
      background: #494949!important;
      // opacity: 0.2;
      margin-left: 7px;
    }
    .swiper-pagination-bullet-active {
      background-color: #fff !important;
      opacity: 1;
    }
  }
}
</style>
<style>
#app .vjs-custom-skin>.video-js .vjs-big-play-button{
  width: 1.5em !important;
}
</style>