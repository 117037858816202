import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import './assets/css/app.css'
Vue.config.productionTip = false
import VuevideoPlayer from 'vue-video-player';

import 'video.js/dist/video-js.css';

Vue.use(VuevideoPlayer);
Vue.prototype.imgUrl =  process.env.VUE_APP_BASE_API + '/app/image/view/'
Vue.prototype.vidoUrl =  process.env.VUE_APP_BASE_API + '/app/media/view/'

new Vue({
  store,
  router,
  render: h => h(App)
}).$mount('#app')
