import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    list: [],
    park: {},
    space: {},
    public: {},
    activities: {},
    industrial: {},
    contactus: {},
    comm: {},
  },
  getters: {
  },
  mutations: {
    setData(state, list){
      state.list = list
      list.forEach(element => {
        if(element.id === 1){
          state.park = element
        }else if(element.id === 2){
          state.space = element
        }else if(element.id === 3){
          state.public = element
        }else if(element.id === 4){
          state.activities = element
        }else if(element.id === 5){
          state.industrial = element
        }else if(element.id === 6){
          state.contactus= element
        }else if(element.id === 11){
          state.comm = element
        }
      });
    }
  },
  actions: {
  },
  modules: {
  }
})
