<template>
  <div class="container">
    <Page v-model="keys" :info="info" :showTitle="false">
      <template slot="left">
        <div class="title">
          <a class="active">{{ left.cnName }}</a>
        </div>
        <Swiper class="swiper-right" :list="leftList">
          <template v-slot="slotProps">
            <div
              v-for="(item, index) in leftList"
              :key="index"
              class="swiper-item"
              :style="'width:' + slotProps.width + 'px;'"
            >
            <div
                class="activityItemBox clearfix"
                v-for="(d, i) in item"
                :key="i"
                @click="path(d.linkUrl)"
              >
                <div
                  class="imgBox"
                  :style="
                    'background-image: url(' +
                    (imgUrl + d.image) +
                    ');background-repeat: no-repeat;background-position: center center;background-size: cover;'
                  "
                ></div>
                <div class="activityIntro">
                  <h6>
                    {{ d.title }}
                  </h6>
                  <p>
                    {{ d.content }}
                  </p>
                  <p>{{ d.createTime }}</p>
                </div>
              </div>
            </div>
          </template>
        </Swiper>
      </template>
      <template slot="right">
        <div class="title">
          <a class="active">{{ right.cnName }}</a>
        </div>
        <Swiper class="swiper-right" :list="rightList">
          <template v-slot="slotProps">
            <div
              v-for="(item, index) in rightList"
              :key="index"
              class="swiper-item"
              :style="'width:' + slotProps.width + 'px;'"
            >
              <div
                class="activityItemBox clearfix"
                v-for="(d, i) in item"
                :key="i"
                @click="path(d.linkUrl)"
              >
                <div
                  class="imgBox"
                  :style="
                    'background-image: url(' +
                    (imgUrl + d.image) +
                    ');background-repeat: no-repeat;background-position: center center;background-size: cover;'
                  "
                ></div>
                <div class="activityIntro">
                  <h6>
                    {{ d.title }}
                  </h6>
                  <p>
                    {{ d.content }}
                  </p>
                  <p>{{ d.createTime }}</p>
                </div>
              </div>
            </div>
          </template>
        </Swiper>
      </template>
    </Page>
    <Foot></Foot>
  </div>
</template>

<script>
import Page from "@/components/page.vue";
import Foot from "@/components/foot.vue";
import Swiper from "@/components/swiper.vue";
export default {
  name: "ActivitiesP",
  components: {
    Foot,
    Page,
    Swiper,
  },
  data() {
    return {
      keys: []
    };
  },
  computed: {
    info() {
      return JSON.parse(JSON.stringify(this.$store.state.activities || {}));
    },
    left() {
      let list = (this.info.children || []).filter((item) => item.id === 9);
      return JSON.parse(JSON.stringify(list[0] || {}));
    },
    right() {
      let list = (this.info.children || []).filter((item) => item.id === 10);
      return JSON.parse(JSON.stringify(list[0] || {}));
    },
    leftList() {
      let list = this.left.information || [];
      let res = [];
      let num = 20;
      let len = Math.ceil(list.length / num);
      for (let i = 0; i < len; i++) {
        res.push(list.slice(i * num, (i + 1) * num));
      }
      return res;
    },
    rightList() {
      let list = this.right.information || [];
      let res = [];
      let num = 20;
      let len = Math.ceil(list.length / num);
      for (let i = 0; i < len; i++) {
        res.push(list.slice(i * num, (i + 1) * num));
      }
      return res;
    },
  },
  created() {},
  methods: {
    path(url) {
      if (url) {
        window.open(url, "_blank");
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.swiper-right {
  height: 89.5%;
  margin-top: 30px;
  .swiper-item {
    float: left;
    height: 100%;
  }
}

.title {
  text-align: center;
  height: 40px;
}
.title.more {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  overflow-y: hidden;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}
.title::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 10px; /*高宽分别对应横竖滚动条的尺寸*/
  height: 6px;
}
.title::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: #535353;
}
.title::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  background: #a1a1a1;
}
.title a {
  display: inline-block;
  padding: 2px 13px;
  min-width: 22%;
  min-width: 90px;
  font-size: 14px;
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 5px;
  margin: 0 5px;
  line-height: 26px;
  height: 26px;
  cursor: pointer;
  margin-right: 5px;
  white-space: nowrap;
}
.title a.active {
  /* background-color: #315dcd; */
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#618dfe),
    to(#315dcd)
  );
  background: linear-gradient(to bottom, #618dfe, #315dcd);
}

.activityItemBox {
  width: 370px;
  margin: 8% auto;
}
@media screen and (max-height: 900px) {
  .activityItemBox {
    margin: 3% auto;
  }
}
.activityItemBox .imgBox {
  width: 133px;
  height: 109px;
  float: left;
}
.activityItemBox > div.activityIntro {
  width: 237px;
  height: 100%;
  float: left;
  text-align: left;
  padding-left: 13px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.activityItemBox > div.activityIntro h6 {
  font-size: 14px;
  overflow: hidden;
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.activityItemBox > div p {
  font-size: 12px;
  color: #959595;
  line-height: 18px;
  margin-top: 9px;
  overflow: hidden;
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
</style>

