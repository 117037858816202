import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Park from '../views/Park.vue'
import Space from '../views/Space.vue'
import Public from '../views/Public.vue'
import Activities from '../views/Activities.vue'
import AboutUs from '../views/AboutUs.vue'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/park',
    name: 'park',
    component: Park
  }
  ,
  {
    path: '/space',
    name: 'space',
    component: Space
  },
  {
    path: '/public',
    name: 'public',
    component: Public
  },
  {
    path: '/activities',
    name: 'activities',
    component: Activities
  },
  {
    path: '/aboutus',
    name: 'aboutus',
    component: AboutUs
  },
]

const router = new VueRouter({
  routes
})

export default router
