<template>
  <div class="container">
    <Page v-model="keys" :info="info" @chage="chageFn" @bclick="serviceCloseFn">
      <template slot="left">
        <div class="title" :class="banners.length > 3 ? 'more' : ''">
          <a
            :class="bannerIndex === index ? 'active' : ''"
            v-for="(item, index) in banners"
            :key="index"
            @click="bannerIndex = index"
            >{{ item.name }}</a
          >
        </div>
        <Swiper class="swiper" :list="bannerImg" :auto="true">
          <template v-slot="slotProps">
            <div
              v-for="(item, index) in bannerImg"
              :key="index"
              class="item"
              :style="
                'width:' +
                slotProps.width +
                'px;' +
                'background: url(' +
                (imgUrl + item) +
                ') center center / cover no-repeat;'
              "
            ></div>
          </template>
        </Swiper>
        <div class="servce-box clearfix">
          <template v-for="(item, index) in tags">
            <div class="li" v-if="index < 5" :key="index">
              <p class="l-a">
                <img :src="imgUrl + item.logo" />
              </p>
              <p class="l-b">{{ item.name }}</p>
            </div>
          </template>
        </div>
        <div class="introductionBox ql-editor">
          <h3 v-html="active.cnName"></h3>
          <div class="scroller" v-html="active.description"></div>
        </div>
      </template>
      <template slot="right">
        <div class="title">
          <a class="active">{{ active.nameAlias }}</a>
        </div>
        <Swiper class="swiper-right" :list="bannerImg">
          <template v-slot="slotProps">
            <div
              v-for="(item, index) in rightList"
              :key="index"
              class="swiper-item"
              :style="'width:' + slotProps.width + 'px;'"
            >
              <div class="service-list clearfix">
                <div
                  class="item"
                  v-for="(d, i) in item"
                  :key="i"
                  @click="itemFn(d)"
                >
                  <div
                    class="i-img"
                    :style="
                      'background: url(' +
                      (imgUrl + d.logo) +
                      ')center center / cover no-repeat;'
                    "
                  ></div>
                  <p>{{ d.name }}</p>
                </div>
              </div>
            </div>
          </template>
        </Swiper>
      </template>
    </Page>
    <Service ref="service"></Service>
    <Foot></Foot>
  </div>
</template>

<script>
import Page from "@/components/page.vue";
import Foot from "@/components/foot.vue";
import Swiper from "@/components/swiper.vue";
import Service from "@/components/service.vue";
export default {
  name: "spaceP",
  components: {
    Foot,
    Page,
    Swiper,
    Service
  },
  data() {
    return {
      keys: [],
      bannerIndex: 0,
    };
  },
  computed: {
    info() {
      return JSON.parse(JSON.stringify(this.$store.state.space || {}));
    },
    active() {
      let obj = {};
      let list = this.info.children || [];
      this.keys.forEach((id) => {
        list.forEach((item) => {
          if (item.id === id) {
            obj = item;
            list = item.children || [];
          }
        });
      });
      return obj;
    },
    banners() {
      return (JSON.parse(JSON.stringify(this.active.banners || []))).map((item) => {
        item.images = (item.images || "").split(",").filter((item) => item);
        return item;
      });
    },
    bannerImg() {
      return this.banners[this.bannerIndex]?.images || [];
    },
    tags() {
      return this.active.tags || [];
    },
    rightList() {
      let list = this.active.attributes || [];
      let res = [];
      let num = 20;
      let len = Math.ceil(list.length / num);
      for (let i = 0; i < len; i++) {
        res.push(list.slice(i * num, (i + 1) * num));
      }
      return res;
    },
  },
  created() {},
  methods: {
    itemFn(item) {
      console.log(this.keys);
      // let str = this.keys.indexOf(13) === -1 ?  'public' : ''
      this.$refs.service.showFn(item, '')
    },
    serviceCloseFn(){
      this.$refs.service.hideFn({})
    },
    chageFn(status){
      if(!status){
        this.$refs.service.hideFn({})
      }
    }
  },
};
</script>
<style lang="scss" scoped>
.swiper {
  height: 220px;
  .item {
    float: left;
    height: 100%;
  }
}
.swiper-right {
  height: 89.5%;
  margin-top: 30px;
  .swiper-item {
    float: left;
    height: 100%;
  }
}

.title {
  text-align: center;
  height: 40px;
}
.title.more {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  overflow-y: hidden;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}
.title::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 10px; /*高宽分别对应横竖滚动条的尺寸*/
  height: 6px;
}
.title::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: #535353;
}
.title::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  background: #a1a1a1;
}
.title a {
  display: inline-block;
  padding: 2px 13px;
  min-width: 22%;
  min-width: 90px;
  font-size: 14px;
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 5px;
  margin: 0 5px;
  line-height: 26px;
  height: 26px;
  cursor: pointer;
  margin-right: 5px;
  white-space: nowrap;
}
.title a.active {
  /* background-color: #315dcd; */
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#618dfe),
    to(#315dcd)
  );
  background: linear-gradient(to bottom, #618dfe, #315dcd);
}
.servce-box {
  margin-top: 10px;
  width: 100%;
}
.servce-box .li {
  width: 20%;
  float: left;
  text-align: center;
  cursor: pointer;
}
.servce-box .li .l-a {
  background-color: rgba(0, 0, 0, 0.6);
  margin: 0 auto;
  width: 55px;
  height: 55px;
  text-align: center;
  line-height: 55px;
  border-radius: 50%;
}
.servce-box .li .l-a img {
  max-height: 30px;
  max-width: 30px;
  vertical-align: middle;
  margin-bottom: 2px;
}
.servce-box .li .l-b {
  text-align: center;
  margin-top: 10px;
  font-size: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.servce-box .li.active .l-a {
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#618dfe),
    to(#315dcd)
  );
  background: linear-gradient(to bottom, #618dfe, #315dcd);
}
.servce-box .li:hover .l-a {
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#618dfe),
    to(#315dcd)
  );
  background: linear-gradient(to bottom, #618dfe, #315dcd);
}
.introductionBox {
  margin-top: 25px;
  height: 33%;
  text-align: left;
}
.introductionBox h3 {
  font-size: 14px;
  color: #fcff00;
  line-height: 30px;
}
.introductionBox div {
  font-size: 14px;
  color: #fff;
  line-height: 24px;
  height: 87%;
  overflow: auto;
  padding-right: 2px;
}
.service-list {
  padding: 0 55px;
}
.service-list .item {
  width: 25%;
  height: 88px;
  text-align: center;
  float: left;
  cursor: pointer;
}
.service-list .item p {
  font-size: 12px;
  margin-top: 5px;
  line-height: 20px;
  height: 20px;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.service-list .item .i-img {
  width: 60px;
  height: 60px;
  overflow: hidden;
  display: inline-block;
  background-size: cover !important;
  border-radius: 10px;
}
.service-list .item:nth-child(n + 5) {
  margin-top: 3%;
}
.swiper-button-prev {
  cursor: pointer;
  background-image: none;
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 50%;
  width: 40px;
  height: 40px;
  text-align: center;
  line-height: 40px;
}
.swiper-button-next {
  cursor: pointer;
  background-image: none;
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 50%;
  width: 40px;
  height: 40px;
  text-align: center;
  line-height: 40px;
}
.swiper-button-prev i {
  font-size: 20px;
  color: #fff;
  vertical-align: top;
}
.swiper-button-next i {
  font-size: 20px;
  color: #fff;
  vertical-align: top;
}
.serviceLogoSmall {
  height: 85px;
}
.service-list .item.serviceLogoSmall .i-img {
  width: 40px;
  height: 40px;
  margin: 10px;
 
  background-size: contain !important;
}

</style>

